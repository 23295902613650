// choir member quote
import React from "react";
import styled from "styled-components";
import { mainBlack, screen, mainYellow, mainWhite } from "../common/variables";
import IconQuotes from "../../images/svg/icon-quotes.svg";

const Wrapper = styled.section`
  background: ${mainBlack};
  text-align: center;
  padding: 41px 22px 50px 22px;
  @media ${screen.xsmall} {
    padding: 100px 42px;
  }
  @media ${screen.small} {
    padding: 100px 30px;
  }
  @media ${screen.large} {
    padding: 110px 0;
  }

  .icon {
    display: block;

    svg {
      width: 76px;
      @media ${screen.large} {
        width: 105px;
      }
    }
  }

  .msg {
    color: ${mainWhite};
    font-size: 1.55rem;
    max-width: 1040px;
    margin: 15px auto 0 auto;
    line-height: 1.44;
    @media ${screen.xsmall} {
      font-size: 1.85rem;
      margin: 38px auto 0 auto;
    }
    @media ${screen.large} {
      font-size: 2.2rem;
    }
  }

  .author {
    color: ${mainYellow};
    text-transform: uppercase;
    font-size: 1.25rem;
    font-weight: 600;
    display: block;
    margin: 38px 0 0 0;
    letter-spacing: 2px;
    @media ${screen.xsmall} {
      font-size: 1.35rem;
      margin: 34px 0 0 0;
    }
    @media ${screen.large} {
      font-size: 1.5rem;
      margin: 38px 0 0 0;
    }
  }
`;

const StyledButton = ({ message, author, className }) => {
  return (
    <Wrapper className={className}>
      <span className="icon">
        <IconQuotes />
      </span>
      <p className="msg">{message}</p>
      <span className="author">{author}</span>
    </Wrapper>
  );
};

export default StyledButton;
