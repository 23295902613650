import React, { useState } from "react";
import { navigate } from "gatsby";
import styled from "styled-components";
import {
  mainWhite,
  mainBlack,
  mainRed,
  siteWidth,
  mainYellow,
  screen,
} from "../common/variables";
import { formatDate, getFirstNameOnly } from "../../helpers";
import StyledButton from "../styled-button";
import Spinner from "../../images/svg/spinner.svg";
import emailjs from "emailjs-com";

const Wrapper = styled.div`
  background: ${mainBlack};
  color: ${mainWhite};
  padding: 41px 14px 50px 14px;
  @media ${screen.xsmall} {
    padding: 100px 42px;
  }
  @media ${screen.small} {
    padding: 100px 30px;
  }
  @media ${screen.large} {
    padding: 110px 0;
  }

  .capital-subheading {
    text-align: center;
    margin: 0 0 50px 0;
  }

  .form {
    text-align: center;
    &__row {
      margin: 0 0 39px 0;
      @media ${screen.xsmall} {
        margin: 0 0 44px 0;
      }

      label {
        font-size: 1.22rem;
        font-weight: 400;
        display: block;
        margin: 0 0 10px 0;
        @media ${screen.xsmall} {
          font-size: 1.4rem;
        }
        @media ${screen.large} {
          font-size: 1.5rem;
        }
        span {
          color: ${mainRed};
        }
      }

      input,
      textarea,
      select {
        color: ${mainBlack};
        outline-color: ${mainYellow};
        width: 100%;
        max-width: 280px;
        padding: 8px 4px;
        font-size: 1.3rem;
        background: ${mainWhite};
        border-radius: 0;
        border: 0;
        @media ${screen.xsmall} {
          max-width: 470px;
        }
      }

      input {
        padding: 8px;
      }

      textarea {
        height: 155px;
        resize: none;
      }
    }

    .btn {
      button {
        color: ${mainWhite};
      }
    }
  }

  .loader {
    position: fixed;
    background: rgba(255, 255, 255, 0.95);
    width: 100%;
    height: 100%;
    max-width: ${siteWidth};
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;

    svg {
      width: 145px;
      height: 145px;
      @media ${screen.xsmall} {
        width: 170px;
        height: 170px;
      }
      @media ${screen.medium} {
        width: 200px;
        height: 200px;
      }
    }
  }
`;

const Form = ({ heading, volunteer, booking, sponsor, partner }) => {
  // store user input
  const [userInput, setUserInput] = useState({});
  // loading overlay state
  const [isLoading, setIsLoading] = useState(false);

  // conditionally subject for email with emailjs
  const subject = booking
    ? "Booking Submission"
    : sponsor
    ? "Sponsor Submission"
    : partner
    ? "Partner Submission"
    : volunteer
    ? "Volunteer Submission"
    : "Website Form Submission";

  // form handler onSubmit
  const handleSubmit = (e) => {
    e.preventDefault();
    // show loading overlay
    setIsLoading(true);

    // if for booking format the date in the object
    // adding subject conditionally
    const inputsToSend = booking
      ? { ...userInput, subject, date: formatDate(userInput.date) }
      : { ...userInput, subject };

    // emailjs function
    emailjs
      .send(
        "smpt_server",
        "general_form",
        inputsToSend,
        "user_z0DJqaw2UnudJGaZpq6Qi"
      )
      // ON SUCCESS
      .then((response) => {
        // reset user input
        setUserInput({});
        // redirection to thank-you page with user's first name
        navigate("/thank-you/", {
          state: { name: getFirstNameOnly(userInput.name) },
        });
      })
      // ON ERROR
      .catch((e) => {
        // hide loading overlay
        setIsLoading(false);
        alert(
          "Something went wrong. Please try again later or contact us directly by email or phone."
        );
        // reset user input
        setUserInput({});
        console.log("error", e);
      });
  };

  // input handler onChange
  const handleInput = (e) => {
    setUserInput({
      ...userInput,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Wrapper>
      {isLoading && (
        <div className="loader">
          <Spinner />
        </div>
      )}
      <h5 className="capital-subheading">{heading || "Heading"}</h5>
      <form onSubmit={handleSubmit} className="form">
        <p className="form__row">
          <label htmlFor="name">
            Full Name<span>*</span>
          </label>
          <input
            type="text"
            name="name"
            onChange={handleInput}
            value={userInput.name || ""}
            required
          />
        </p>
        <p className="form__row">
          <label htmlFor="email">
            Email Address<span>*</span>
          </label>
          <input
            type="email"
            name="email"
            onChange={handleInput}
            value={userInput.email || ""}
            required
          />
        </p>
        <p className="form__row">
          <label htmlFor="phone">
            Phone Number<span>*</span>
          </label>
          <input
            type="phone"
            name="phone"
            onChange={handleInput}
            value={userInput.phone || ""}
            required
          />
        </p>
        {partner && (
          <p className="form__row">
            <label htmlFor="company">
              Company<span>*</span>
            </label>
            <input
              type="text"
              name="company"
              onChange={handleInput}
              value={userInput.company || ""}
              required
            />
          </p>
        )}
        {sponsor && (
          <p className="form__row">
            <label htmlFor="company">
              Company<span>*</span>
            </label>
            <input
              type="text"
              name="company"
              onChange={handleInput}
              value={userInput.company || ""}
              required
            />
          </p>
        )}
        {volunteer && (
          <p className="form__row">
            <label htmlFor="type">
              What kind of volunteer?<span>*</span>
            </label>
            <select
              name="kind"
              onChange={handleInput}
              value={userInput.kind || ""}
              style={{ color: userInput.kind ? mainBlack : "#a8acb2" }}
              required
            >
              <option value="">Choose one of the following:</option>
              <option value="Musical">Musical Volunteers</option>
              <option value="Singing">Singing Volunteers</option>
              <option value="Welfare">Welfare Volunteers</option>
              <option value="Administrative">Administrative Volunteers</option>
            </select>
          </p>
        )}

        {booking && (
          <p className="form__row">
            <label htmlFor="date">
              Date of booking<span>*</span>
            </label>
            <input
              type="date"
              name="date"
              onChange={handleInput}
              value={userInput.date || ""}
              style={{ color: userInput.date ? mainBlack : "#a8acb2" }}
              required
            />
          </p>
        )}

        <p className="form__row">
          <label htmlFor="message">
            Message<span>*</span>
          </label>
          <textarea
            name="message"
            onChange={handleInput}
            value={userInput.message || ""}
            required
          ></textarea>
        </p>
        <StyledButton className="btn">SUBMIT FORM</StyledButton>
      </form>
    </Wrapper>
  );
};

export default Form;
