// styled button across the site
import React from "react";
import styled from "styled-components";
import {
  mainRed,
  mainBlack,
  screen,
  mainYellow,
  mainWhite,
} from "../common/variables";
import { Link } from "gatsby";

const Wrapper = styled.div`
  button,
  a {
    background: none;
    border: 2px ${mainRed} solid;
    color: ${mainBlack};
    display: inline-block;
    font-size: 1.02rem;
    font-weight: 600;
    padding: 13px 23px 12px 23px;
    border-radius: 5px;
    letter-spacing: 3px;
    outline: none;
    cursor: pointer;
    line-height: 20px;
    min-width: 180px;
    text-align: center;
    @media ${screen.xsmall} {
      min-width: 170px;
    }
    @media ${screen.large} {
      padding: 15px 26px 14px 26px;
      font-size: 1.1rem;
    }
    @media ${screen.withCursor} {
      &:hover {
        background: ${mainYellow};
        border: 2px ${mainYellow} solid;
        color: ${mainWhite};
      }
    }
  }
`;

const StyledButton = ({ children, to, className, onClick, style, href }) => {
  return (
    <Wrapper className={className} onClick={onClick} style={style}>
      {/* link tag if "to" prop passed  */}

      {to ? (
        <Link to={to}>{children || "Label here"}</Link>
      ) : href ? (
        <a href={href} target="_blank" rel="noreferrer">
          {children || "Label here"}
        </a>
      ) : (
        <button>{children || "Label here"}</button>
      )}
    </Wrapper>
  );
};

export default StyledButton;
