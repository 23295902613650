import React from "react";
import Layout from "../components/layout";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import styled from "styled-components";
import {
  mainBlack,
  mainRed,
  mainWhite,
  innerWidth,
  lightGrey,
  screen,
} from "../components/common/variables";
import SEO from "../components/seo";
import SubBanner from "../components/banner/sub";
import Quote from "../components/quote";
import Form from "../components/form";

const Wrapper = styled.div`
  .banner {
    position: relative;
    background: ${lightGrey};
    border-top: 22px ${mainWhite} solid;
    @media ${screen.xsmall} {
      border-top: 15px ${mainWhite} solid;
      border-bottom: 15px ${mainWhite} solid;
    }
    @media ${screen.large} {
      border-top: 17px ${mainWhite} solid;
      border-bottom: 17px ${mainWhite} solid;
    }

    .inner-wrapper {
      .featured-txt {
        text-align: center;
        @media ${screen.small} {
          text-align: left;
        }

        .inner-txt {
          .capital-heading {
            margin: 12px 0 0 0;
            @media ${screen.xsmall} {
              margin: 25px 0 0 0;
            }
            @media ${screen.large} {
              max-width: 540px;
            }
          }
        }
      }
    }

    .bg {
      order: -1;
      @media ${screen.small} {
        order: 0;
      }
    }
  }

  .row {
    background: ${mainWhite};

    .inner-wrapper {
      max-width: ${innerWidth};
      margin: 0 auto;
      padding: 41px 0 50px 0;
      display: flex;
      flex-direction: column;
      @media ${screen.xsmall} {
        padding: 100px 42px;
      }
      @media ${screen.small} {
        flex-direction: row;
        padding: 100px 30px;
      }
      @media ${screen.large} {
        padding: 110px 0;
      }

      .col {
        @media ${screen.small} {
          width: 50%;
          padding: 0 30px 0 0;
        }

        .capital-subheading {
          margin: 0 0 22px 0;
          text-align: center;
          padding: 0 22px;
          @media ${screen.xsmall} {
            margin: 0 0 30px 0;
            padding: 0;
          }
          @media ${screen.small} {
            max-width: 530px;
            text-align: left;
          }
          @media ${screen.large} {
            margin: 0 0 35px 0;
          }
        }

        &--right {
          padding: 0;

          @media ${screen.small} {
            margin: 0 0 0 10px;
          }

          .bordered-p {
            border-bottom: 2px ${mainRed} solid;
            margin: 0 0 25px 0;
            padding: 0 0 25px 0;
            text-align: center;
            @media ${screen.xsmall} {
              padding: 0 0 50px 0;
              margin: 0 0 50px 0;
              text-align: left;
            }

            p {
              font-size: 1.22rem;
              font-weight: 400;
              @media ${screen.xsmall} {
                font-size: 1.4rem;
              }
              @media ${screen.large} {
                font-size: 1.5rem;
              }
            }

            strong {
              font-weight: 600;
            }

            &:last-child {
              border-bottom: none;
              padding: 0;
              margin: 0;
            }
          }

          .logo-list {
            display: flex;
            flex-wrap: wrap;
            @media ${screen.xsmall} {
              margin: 0 -10px;
            }

            &__each {
              width: 100%;
              margin: 0 0 38px 0;
              @media ${screen.xsmall} {
                width: calc(33.33% - 40px);
                margin: 0 20px 20px 20px;
              }
              @media ${screen.small} {
                width: calc(50% - 40px);
                margin: 0 20px 20px 20px;
              }

              &:last-child {
                margin: 0;
                @media ${screen.xsmall} {
                  margin: 10px;
                }
              }
            }
          }
        }

        .btn {
          display: none;
          @media ${screen.small} {
            display: block;
          }

          &--mobile {
            display: block;
            text-align: center;
            margin: 30px 0 0 0;

            @media ${screen.small} {
              display: none;
            }
          }
        }
      }
    }

    &--current-partners {
      background: ${lightGrey};
      padding: 0 22px;
      @media ${screen.xsmall} {
        padding: 0;
      }

      .col {
        .capital-subheading {
          @media ${screen.small} {
            padding: 70px 0 0 0 !important;
          }
        }
      }
    }
  }

  .quote {
    background: ${mainWhite};

    .msg {
      color: ${mainBlack};
    }
  }
`;

const SponsorUsPage = ({ data }) => {
  const {
    why_sponsor_heading,
    sponsors_heading,
    top_description,
    bottom_description,
    quote_message,
    quote_author,
    banner_heading,
    banner_image,
    list,
    meta_description,
    title_tag,
  } = data.content.data;

  return (
    <Layout>
      <SEO title={title_tag} description={meta_description} />
      <Wrapper>
        <SubBanner className="banner" bgUrl={banner_image.url}>
          <div className="inner-wrapper">
            <div className="featured-txt">
              <div className="inner-txt">
                <p className="red-heading">GET INVOLVED</p>
                <h1 className="capital-heading">{banner_heading.text}</h1>
              </div>
            </div>
          </div>
        </SubBanner>

        <section className="row">
          <div className="inner-wrapper">
            <div className="col">
              <h3 className="capital-subheading">{why_sponsor_heading.text}</h3>
            </div>

            <div className="col col--right">
              <div>
                <div
                  className="bordered-p"
                  dangerouslySetInnerHTML={{ __html: top_description.html }}
                />
                <div
                  className="bordered-p"
                  dangerouslySetInnerHTML={{
                    __html: bottom_description.html,
                  }}
                />
              </div>
            </div>
          </div>
        </section>

        <section className="row row--current-partners">
          <div className="inner-wrapper">
            <div className="col">
              <h3 className="capital-subheading">{sponsors_heading.text}</h3>
            </div>

            <div className="col col--right">
              <ul className="logo-list">
                {list.map((item, i) => (
                  <li className="logo-list__each" key={i}>
                    <a href={item.website_url} target="_blank" rel="noreferrer">
                      <GatsbyImage
                        image={item.logo.gatsbyImageData}
                        alt={item.logo.alt || "Brand Logo"}
                      />
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </section>

        <Quote
          className="quote"
          message={quote_message}
          author={quote_author}
        />

        <Form heading={"Become a corporate sponsor"} sponsor />
      </Wrapper>
    </Layout>
  );
};

export default SponsorUsPage;

export const dataQuery = graphql`
  {
    content: prismicSponsorPage {
      data {
        title_tag
        meta_description
        banner_heading {
          text
        }
        banner_image {
          url
          alt
        }
        why_sponsor_heading {
          text
        }
        top_description {
          html
        }
        bottom_description {
          html
        }
        sponsors_heading {
          text
        }
        list {
          website_url
          logo {
            alt
            gatsbyImageData(placeholder: BLURRED)
          }
        }
        quote_message
        quote_author
      }
    }
  }
`;
